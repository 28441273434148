import { Module } from '@/Models'
import menuModule from '@/store/menu'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Sidebar extends Vue {
  expandOnHover = true
  expandWithDelay = false
  mobile = 'reduce'
  reduce = false

  get modules(): Module[] {
    return menuModule.user_modules
  }

  to_path(path: string) {
    if (path && this.$route.path != path && path != '#') this.$router.push(path)
  }
}
